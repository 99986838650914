<template>
  <v-container fluid class="my-4 px-4">
    <v-row justify="center" class="text-center ma-0 tovarBlock">
      <v-col cols="12" class="">
        <v-row justify="center" class="mt-10 mb-4 px-md-8">
          <v-col cols="12" md="6" align-self="center" style="position: relative" v-if="tovar['Images']">
            <v-img class="img_big" max-width="100%" aspect-ratio="1.5" max-height="100%" contain :src="slide_group ? getIMGPathProduct(tovar['Images'][slide_group]) : getIMGPathProduct(tovar['Images'][0])" :alt="tovar.alt" :title="tovar.title">
            </v-img>
            <v-slide-group v-model="slide_group" class="pa-4 slide_img" active-class="success" show-arrows>
              <v-slide-item v-for="n in tovar['Images']" :key="n" v-slot="{ active, toggle }">
                <v-img v-if="n" class="ma-2 imgs" :src="getIMGPathProduct(n)" max-width="70px"
                  contain @click="toggle">
                  <v-row class="fill-height row_img ma-0" :class="active ? 'active_image' : undefined">
                  </v-row>
                </v-img>
              </v-slide-item>
            </v-slide-group>
          </v-col>
          <v-col cols="12" md="6" align-self="center">
            <v-card flat color="transparent" class="card_left text-left">
              <v-card-title class="card_title pa-0 fontB font_wrap">
                <span v-html="getLanguage == 'Ru' ? tovar['Name Ru'] : tovar['Name Ro']
                  "></span>
              </v-card-title>
              <v-card-subtitle class="card_brand fontR pa-0 my-4 font_wrap" v-if="Categorie">
                <span class="card_brand_1" v-html="getLanguage == 'Ru' ? 'Бренд: ' : 'Marca: '"></span>
                <span v-if="Categorie[0]" v-html="getLanguage == 'Ru'
                  ? Categorie[0]['Name Ru']
                  : Categorie[0]['Name Ro']
                  "></span>
              </v-card-subtitle>
              <v-card-subtitle class="card_price fontR pa-0 my-4 font_wrap">
                <span v-html="getLanguage == 'Ru' ? 'Цена: ' : 'Preț: '"></span>
                <span v-html="tovar['Price']"></span>
              </v-card-subtitle>
              <v-divider></v-divider>
              <v-card-subtitle class="card_subtitle fontR pa-0 mt-1 font_wrap">
                <span v-html="getLanguage == 'Ru'
                  ? tovar['Description Ru']
                  : tovar['Description Ro']
                  "></span>
              </v-card-subtitle>
              <v-card-actions class="px-0 mt-10">
                <v-btn @click="addToCardAndBuy($route.params.id)" class="btn_buy fontR px-12" color="primary"
                  elevation="0">
                  {{ getLanguage == 'Ru' ? 'Оформить заявку' : 'Lasa o comanda' }}
                </v-btn>
                <v-btn @click="addToCard($route.params.id)" class="btn_buy_2 fontR px-5" icon outlined elevation="0">
                  <svg width="14" viewBox="0 0 14 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M7 1C8.38071 1 9.5 2.11929 9.5 3.5V4H4.5V3.5C4.5 2.11929 5.61929 1 7 1ZM10.5 4V3.5C10.5 1.567 8.933 0 7 0C5.067 0 3.5 1.567 3.5 3.5V4H0V14C0 15.1046 0.895431 16 2 16H12C13.1046 16 14 15.1046 14 14V4H10.5Z"
                      fill="#DCBB10" />
                  </svg>
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Categories from "../../assets/Categories.json";
import { mapGetters } from "vuex";

export default {
  name: "TovarBlock",
  props: {
    tovar: Object,
  },
  data: () => ({
    slide_group: null,
  }),
  mounted() {
    /* if (this.tovar.Categories) {
      let categ = this.tovar.Categories;
      console.log(categ);
      this.Categorie = Categories.filter(
        (c) =>
          (categ.includes(c["Name code"].toLowerCase()) &&
            c["Name code"].toLowerCase() == "husqvarna_bikes") ||
          c["Name code"].toLowerCase() == "rieju_bikes"
      );
      if (!this.Categorie) {
        this.Categorie = Categories.filter(
          (c) =>
            c["Name code"].toLowerCase() ==
            this.$route.params["name"].toLowerCase()
        );
      }
    } */
  },
  computed: {
    ...mapGetters(["getLanguage"]),
    Categorie() {
      let Categorie2;

      if (this.tovar.Categories) {
        let categorieTovar = this.tovar.Categories;
        let treecateg = "husqvarna_bikes, rieju_bikes, Bud_racing, tm_bikes, Fantic_bikes";
        Categorie2 = Categories.filter(
          (c) =>
            categorieTovar.includes(c["Name code"].toLowerCase()) &&
            treecateg.includes(c["Name code"].toLowerCase())
        );
        if (Categorie2.length == 0) {
          Categorie2 = Categories.filter(
            (c) =>
              c["Name code"].toLowerCase() ==
              this.$route.params["name"].toLowerCase()
          );
        }
      }
      return Categorie2;
    },
  },
  methods: {
    getIMGPathProduct(url) {
      if (url.includes('http')) return url
      return require(`@/assets/img/products/${url}`);
    },
    addToCard(id) {
      this.$store.dispatch("SetCard", id);
    },
    addToCardAndBuy(id) {
      this.$store.dispatch("SetCard", id);
      this.$router.push("/card");
    },
  },
};
</script>

<style>
.slide_img .v-slide-group__content {
  justify-content: center;
}
</style>
<style scoped>
.img_big {
  border-radius: 10px;
}

.imgs {
  border: 1px solid #c8c8c8;
  border-radius: 8px;
}

.row_img {
  transition: 0.2s;
}

.active_image {
  background: rgba(0, 0, 0, 0.1);
}

.btn_buy_2 {
  border: 1.5px solid #dcbb10;
  border-radius: 5px;
}

.btn_buy {
  text-transform: none;
  border-radius: 5px;
  font-weight: 400;
  font-size: 17px;
  line-height: 22px;
  letter-spacing: -0.03em;
  color: #eaeaea;
}

.card_brand {
  font-weight: 500;
  font-size: 26px;
  line-height: 92%;
  letter-spacing: -0.02em;
  color: #040c1b !important;
}

.card_brand_1 {
  font-weight: 400;
  font-size: 26px;
  line-height: 92%;
  letter-spacing: -0.02em;
  color: #dcbb10;
}

.card_price {
  font-weight: 500;
  font-size: 22px;
  line-height: 92%;
  letter-spacing: -0.02em;
  color: #a0a0a0;
}

.tovarBlock {
  background: #fafafa;
  border-radius: 10px;
}

.card_title {
  font-weight: 700;
  font-size: 42px;
  line-height: 50px;
  letter-spacing: -0.055em;
  color: #040c1b;
}

.card_subtitle {
  letter-spacing: -0.01em;
  font-weight: 600;
  font-size: 15px;
  line-height: 18px;
  color: #040c1b;
}

.btn_carousel {
  text-transform: none;
  border-radius: 0px;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  letter-spacing: -0.02em;
}
</style>