<template>
  <v-container v-if="bestTovar" fluid class="pa-0">
    <v-divider></v-divider>
    <v-row justify="center" class="text-center py-15">
      <v-col cols="10" class="">
        <v-row justify="center" class="mt-10 mb-4">
          <v-col cols="12" md="6" align-self="center">
            <v-card flat color="transparent" class="card_left text-left">
              <v-card-title v-if="product" class="card_title pa-0 fontB font_wrap">
                <!-- Новый Husqvarna Enduro Range -->
                {{
                  getLanguage == "Ru" ? product["Name Ru"] : product["Name Ro"]
                }}
              </v-card-title>
              <v-card-subtitle v-if="product" class="card_subtitle fontR pa-0 mt-1 font_wrap pr-lg-12">
                {{
                  getLanguage == "Ru"
                    ? product["Description Ru"]
                    : product["Description Ro"]
                }}
                <!-- Усовершенствованная прогрессивная задняя подвеска, аналогичная
                модели Husqvarna для мотокросса, обеспечивает оптимальный
                контроль и комфорт.
                <ul class="mt-3">
                  <li>Новый кикстартер</li>
                  <li>Новый глушитель</li>
                  <li>Новые распорки ГБЦ</li>
                  <li>Центробежный выхлоп</li>
                  <li>Новая графика</li>
                  <li>Комплект для установки карбюратора</li>
                  <li>Новый комплект подножек</li>
                  <li>Новый винт боковой стойки</li>
                </ul> -->
              </v-card-subtitle>
              <v-card-actions v-if="product" class="px-0">
                <v-btn :href="'/Product/' + thisCateg['Name code'] + '/' + product['ID']
                  " class="mt-3 btn_carousel fontR px-14" color="primary" elevation="0">
                  {{
                    getLanguage == "Ru"
                      ? "Подробнее"
                      : "Citește mai mult"
                  }}
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-col>
          <v-col cols="12" md="6" align-self="center" style="position: relative">
            <v-img v-if="product" class="img_absolute" max-width="100%" aspect-ratio="1.5" max-height="100%" contain
              :src="typeof product['Images'] == 'string'
                  ? getIMGPathProduct(product['Images'].split(',')[0])
                  : getIMGPathProduct(product['Images'][0])
                ">
            </v-img>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import CategoriesJSON from "./../../assets/Categories.json";
import { mapGetters } from "vuex";

export default {
  name: "BestProduct",
  data: () => ({
    thisCateg: {},
    bestTovar: null,
    product: null,
  }),
  mounted() {
    this.thisCateg = CategoriesJSON.filter((categ) =>
      categ["Name code"].includes(
        this.$route.params.name[0].toUpperCase() +
        this.$route.params.name.slice(1)
      )
    )[0];
    if (this.thisCateg) {
      this.bestTovar = this.thisCateg["Best Tovar ID"];
      if (this.bestTovar) {
        if (this.getProducts.length > 0) {
          this.product = this.getProducts.filter(
            (tovar) => tovar["ID"] == this.bestTovar
          )[0];
        }
      }
    }
  },
  computed: {
    ...mapGetters(["getLang", "getLanguage", "getProducts"]),
  },
  methods: {
    getIMGPathProduct(url) {
      if (url.includes('http')) return url
      return require(`@/assets/img/products/${url}`);
    },
  }
};
</script>

<style scoped>
@media only screen and (max-width: 864px) {
  .img_absolute {
    right: 0%;
  }
}

@media only screen and (min-width: 864px) {
  .img_absolute {
    right: 0%;
    transform: scale(1.2);
  }
}

.card_title {
  font-weight: 700;
  font-size: 42px;
  line-height: 50px;
  letter-spacing: -0.055em;
  color: #040c1b;
}

.card_subtitle {
  letter-spacing: -0.05em;
  font-weight: 600;
  font-size: 15px;
  line-height: 18px;
  color: #040c1b;
}

.btn_carousel {
  text-transform: none;
  border-radius: 0px;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  letter-spacing: -0.02em;
}
</style>