<template>
  <div class="CardPage" :class="tab == 2 ? 'Card_dark' : ''">
    <parallaxCard v-if="tab != 2" />
    <v-row justify="center" style="flex-flow: nowrap" class="pt-5">
      <v-col cols="12" sm="10" align-self="start">
        <v-btn class="breadcrumbs_card fontB mx-2" elevation="0" @click="tab = 0">
          {{ getLang.CardPage.item_link1 }}
        </v-btn>
        <v-btn class="breadcrumbs_card fontB mx-2" elevation="0" @click="tab = 0">
          {{ getLang.CardPage.item_link2 }}
        </v-btn>
        <v-btn v-if="tab > 0" class="breadcrumbs_card fontB mx-2" elevation="0" @click="tab = 1">
          {{ getLang.CardPage.item_link3 }}
        </v-btn>
        <v-btn v-if="tab > 1" class="breadcrumbs_card fontB mx-2" elevation="0" @click="tab = 2">
          {{ getLang.CardPage.item_link4 }}
        </v-btn>
      </v-col>
    </v-row>
    <v-row justify="center" class="mb-15">
      <v-col cols="12" sm="11" align-self="start">
        <v-tabs-items v-model="tab" class="tabs_items">
          <v-tab-item>
            <v-row justify="center">
              <v-col cols="12" sm="9">
                <div v-if="getCard" class="card_content mb-12">
                  <v-divider class="silverDivider"></v-divider>
                  <v-list color="transparent" v-for="(item, i) in productsJSON" :key="i">
                    <v-list-item class="item_drawer fontB">
                      <v-row justify="center" class="ma-0">
                        <v-col cols="1" align-self="center">
                          <v-btn @click="DeleteFromCard(item['ID'])" icon color="primary" small>
                            <svg width="13" height="13" viewBox="0 0 13 13" fill="none"
                              xmlns="http://www.w3.org/2000/svg">
                              <path
                                d="M4.79706 6.20652L0.283114 1.65738C-0.0939903 1.27776 -0.0939903 0.663077 0.283114 0.284715C0.658963 -0.0949048 1.26987 -0.0949048 1.64572 0.284715L6.15967 4.83386L10.6749 0.284715C11.0507 -0.0949048 11.6616 -0.0949048 12.0375 0.284715C12.4146 0.664334 12.4146 1.27902 12.0375 1.65738L7.52228 6.20652L12.0362 10.7544C12.4133 11.134 12.4133 11.7487 12.0362 12.1271C11.8489 12.3169 11.6013 12.4112 11.3549 12.4112C11.1085 12.4112 10.8622 12.3169 10.6749 12.1271L6.15967 7.57919L1.64446 12.1283C1.45717 12.3181 1.21079 12.4124 0.963161 12.4124C0.716785 12.4124 0.47041 12.3181 0.283114 12.1283C-0.0939903 11.7487 -0.0939903 11.134 0.283114 10.7557L4.79706 6.20652Z"
                                fill="#040C1B" />
                            </svg>
                          </v-btn>
                        </v-col>
                        <v-col cols="3">
                          <v-img class="rounded-lg img_moto" width="100%" contain aspect-ratio="1.5" :src="typeof item['Images'] == 'string'
                              ? getIMGPathProduct(item['Images'].split(',')[0])
                              : getIMGPathProduct(item['Images'][0])
                            "></v-img>
                        </v-col>
                        <v-col cols="4" align-self="center">
                          <v-list-item-title class="item_shop_text pt-0 font_wrap d-flex">
                            {{
                              getLanguage == "Ru"
                                ? item["Name Ru"]
                                : item["Name Ro"]
                            }}
                          </v-list-item-title>
                        </v-col>
                        <v-col cols="2" align-self="center" class="d-flex">
                          <v-btn @click="RemoveTovar(item['ID'])" icon>
                            <v-icon>mdi-minus</v-icon>
                          </v-btn>
                          <span class="d-flex align-center">{{
                            item.kolicestvo_tovar
                            }}</span>
                          <v-btn @click="AddTovar(item['ID'])" icon>
                            <v-icon>mdi-plus</v-icon>
                          </v-btn>
                        </v-col>
                        <v-col cols="2" align-self="center" class="px-0">
                          <v-list-item-subtitle class="price_shop font_wrap px-0">
                            {{ item["Price"] }}
                          </v-list-item-subtitle>
                        </v-col>
                      </v-row>
                    </v-list-item>
                    <v-divider class="silverDivider mt-2"></v-divider>
                  </v-list>
                </div>
              </v-col>
              <v-col cols="12" sm="3">
                <v-card class="card_right" flat>
                  <v-card-title class="py-4 font_wrap">
                    {{ getLang.CardPage.tovars }}
                  </v-card-title>
                  <v-divider class="silverDivider mb-5"></v-divider>
                  <v-card-subtitle class="mb-5 d-flex font_wrap card_right_subtitle">
                    {{ productsJSON.length }}
                    {{
                      productsJSON.length > 1
                        ? getLang.CardPage.counts_produse
                        : getLang.CardPage.count_produse
                    }}
                    <v-spacer></v-spacer>
                    <b>{{ Price ? Price + ",00 MDL" : 0 }}</b>
                  </v-card-subtitle>
                  <v-card-text class="text_dop">
                    {{ getLang.CardPage.min_price }}
                  </v-card-text>
                  <v-divider class="silverDivider mt-5"></v-divider>
                  <v-row justify="center" class="ma-0">
                    <v-btn @click="Step2()" color="#040c1b" class="btn_zakaz my-5 px-8 py-5 fontB"
                      :disabled="productsJSON.length == 0 || Price == 0">
                      {{ getLang.CardPage.oformiti_zakaz }}
                    </v-btn>
                  </v-row>
                </v-card>
              </v-col>
            </v-row>
          </v-tab-item>
          <v-tab-item>
            <v-form ref="form" v-model="valid" @submit.prevent>
              <v-row justify="center">
                <v-col cols="12" sm="5" lg="4">
                  <v-card class="card_right" flat>
                    <v-card-title class="py-4 font_wrap pl-6">
                      {{ getLang.CardPage.tab_item_2.datele_comenzii }}
                    </v-card-title>

                    <v-text-field v-model="city" :rules="getLanguage == 'Ru' ? rulesRu : rulesRo" class="mx-4"
                      :label="getLang.CardPage.tab_item_2.city" required></v-text-field>
                    <v-text-field v-model="address" :rules="getLanguage == 'Ru' ? rulesRu : rulesRo" class="mx-4"
                      :label="getLang.CardPage.tab_item_2.address" required></v-text-field>
                    <v-text-field v-model="fio" :rules="getLanguage == 'Ru' ? rulesRu : rulesRo" class="mx-4"
                      :label="getLang.CardPage.tab_item_2.fio" required></v-text-field>
                    <v-text-field v-model="email" :rules="getLanguage == 'Ru' ? emailRulesRu : emailRulesRo"
                      class="mx-4" label="Email" required></v-text-field>
                    <v-text-field v-model="tel" :rules="getLanguage == 'Ru' ? rulesRu : rulesRo" class="mx-4"
                      :label="getLang.CardPage.tab_item_2.tel" required></v-text-field>
                    <v-text-field v-model="comm" class="mx-4"
                      :label="getLang.CardPage.tab_item_2.comment"></v-text-field>
                    <v-radio-group v-model="oplana_nal" class="mx-4" required
                      :rules="getLanguage == 'Ru' ? rulesRu : rulesRo">
                      <v-radio :label="getLang.CardPage.tab_item_2.oplata_nal" value="1"></v-radio>
                      <v-radio class="mt-3" value="2" :label="getLang.CardPage.tab_item_2.oplata_card"></v-radio>
                    </v-radio-group>
                    <v-checkbox v-model="ne_perezvanivati" class="mx-4 mt-0"
                      :label="getLang.CardPage.tab_item_2.not_telefona" color="#05238B"></v-checkbox>
                  </v-card>
                </v-col>
                <v-col cols="12" sm="5" lg="4">
                  <v-card class="card_right" height="100%" flat>
                    <v-card-title class="py-4 font_wrap pl-6">
                      {{ getLang.CardPage.tab_item_2.itogo }}
                    </v-card-title>
                    <v-divider class="silverDivider mb-5 mx-3"></v-divider>
                    <v-card-subtitle class="mb-5 d-flex font_wrap card_right_subtitle">
                      {{ productsJSON.length }}
                      {{
                        productsJSON.length > 1
                          ? getLang.CardPage.counts_produse
                          : getLang.CardPage.count_produse
                      }}
                      <v-spacer></v-spacer>
                      <b>{{ Price ? Price + ",00 MDL" : 0 }}</b>
                    </v-card-subtitle>
                    <v-card-text class="text_dop">
                      {{ getLang.CardPage.min_price }}
                    </v-card-text>
                    <v-divider class="silverDivider mt-5 mx-3"></v-divider>
                    <v-row justify="center" class="mx-0 my-3">
                      <v-col cols="8" class="pa-0" align-self="center">
                        <v-checkbox v-model="express_diverli" class="mx-4"
                          :label="getLang.CardPage.tab_item_2.express"></v-checkbox>
                      </v-col>
                      <v-col cols="4" class="pa-0 fontB" align-self="center">
                        +200 MDL
                      </v-col>
                    </v-row>
                    <v-divider class="silverDivider mb-5 mx-3"></v-divider>
                    <v-card-subtitle class="text-center itogo mb-0 pb-0">
                      {{ getLang.CardPage.itogo }}
                    </v-card-subtitle>
                    <v-card-title class="text-center justify-center all_price mt-0 pt-0">
                      {{ TotalPrice ? TotalPrice + ",00 MDL" : 0 }}
                    </v-card-title>
                    <v-row justify="center" class="ma-0">
                      <v-btn @click="Step3()" color="#040c1b" type="submit" class="btn_zakaz my-5 px-8 py-5 fontB"
                        :disabled="!valid">
                        {{ getLang.CardPage.oformiti_zakaz }}
                      </v-btn>
                    </v-row>
                    <v-checkbox required :rules="getLanguage == 'Ru' ? rulesRu : rulesRo" v-model="accept_conditions"
                      class="mx-4" :label="getLang.CardPage.tab_item_2.confirm_all"></v-checkbox>
                  </v-card>
                </v-col>
              </v-row>
            </v-form>
          </v-tab-item>
          <v-tab-item>
            <v-divider class="hr_silver"></v-divider>
            <v-card-title class="tab2_title justify-center mt-10">
              {{ getLang.CardPage.tab_item_3.title }}
            </v-card-title>
            <v-card-subtitle class="tab2_subtitle text-center mt-1">
              {{ getLang.CardPage.tab_item_3.subtitle }}
            </v-card-subtitle>
            <v-row justify="center">
              <v-col cols="12" sm="11" md="10" lg="8">
                <v-img class="ma-auto img_rotate" max-width="100%" src="../assets/Card/3.png">
                  <img width="100%" src="../assets/Card/2.png" alt="stars" />
                </v-img>
              </v-col>
            </v-row>
          </v-tab-item>
        </v-tabs-items>
      </v-col>
    </v-row>
    <FooterPage class="mt-15" />
  </div>
</template>

<script>
import parallaxCard from "../components/Card/parallaxCard.vue";
import FooterPage from "../components/FooterPage.vue";
import productsJSON2 from "../assets/products.json";
import { mapGetters } from "vuex";
import axios from "axios";

export default {
  name: "CardPage",
  components: {
    parallaxCard,
    FooterPage,
  },
  data: () => ({
    tab: 0,
    city: "",
    address: "",
    fio: "",
    email: "",
    tel: "",
    comm: "",
    oplana_nal: "",
    ne_perezvanivati: "",
    express_diverli: "",
    accept_conditions: "",
    valid: true,
    emailRulesRu: [
      (v) => !!v || "E-mail обьязательный",
      (v) => /.+@.+\..+/.test(v) || "E-mail должен быть действительный",
    ],
    emailRulesRo: [
      (v) => !!v || "E-mail obligatoriu",
      (v) => /.+@.+\..+/.test(v) || "E-mail trebuie să fie valabil",
    ],
    rulesRu: [
      (value) => {
        if (value) return true;
        return "Пожалуйста заполните это поле";
      },
    ],
    rulesRo: [
      (value) => {
        if (value) return true;
        return "Vă rugăm să completați acest câmp";
      },
    ],
    overlay: false,
  }),
  mounted() { },
  computed: {
    ...mapGetters(["getLanguage", "getLang", "getCard"]),
    productsJSON() {
      let kol_vo = Object.values(this.getCard);
      let productsJSON = productsJSON2
        .filter((prod) =>
          Object.keys(this.getCard)
            .map((g) => Number(g))
            .includes(prod["ID"])
        )
        .map((c, i) => {
          c.kolicestvo_tovar = kol_vo[i];
          return c;
        });
      return productsJSON;
    },
    Price() {
      let price = 0;
      let kol_vo = Object.values(this.getCard);
      this.productsJSON.forEach((element, i) => {
        price +=
          Number(
            element["Price"]
              .replace("MDL", "")
              .replace(",00", "")
              .replace(" ", "")
          ) * kol_vo[i];
      });
      return price;
    },
    TotalPrice() {
      if (this.express_diverli) {
        return this.Price + 200;
      } else {
        return this.Price;
      }
    },
  },
  methods: {
    getIMGPathProduct(url) {
      if (url.includes('http')) return url
      return require(`@/assets/img/products/${url}`);
    },
    submit() {
      this.$refs.observer.validate();
    },
    Step2() {
      this.tab = 1;
    },
    Step3() {
      this.overlay = true;

      let datele = `
    <html><body>
    <h2>Данные заказа</h2><br />
    <b>Город:</b> ${this.city}<br />
    <b>Адресс:</b> ${this.address}<br />
    <b>ФИО:</b> ${this.fio}<br />
    <b>Email:</b> ${this.email}<br />
    <b>Телефон:</b> ${this.tel}<br />
    <b>Комментарий:</b> ${this.comm}<br /><hr />
    <h4>Оплата: ${this.oplana_nal == 1
          ? this.Price + " MDL наличными"
          : this.Price + " MDL картой по  прибытию курьера"
        }</h4><hr />
    ${this.ne_perezvanivati == true ? "<b>Не перезванивать</b>" : ""}<br />
    <b>Доставка:</b> ${this.express_diverli == true ? "Экспресс" : "Обычная"
        }<br /><hr />
    <h4>Товары:</h4>`;
      this.productsJSON.forEach((pr) => {
        datele +=
          "Название: " +
          pr["Name Ru"] +
          "  Кол-во: " +
          pr["kolicestvo_tovar"] +
          "<hr />";
      });
      datele += "<br /><br /></body></html>";
      axios
        .post("/SendComanda.php", {
          datele: datele,
        })
        .then(() => {
          this.overlay = false;
          this.tab = 2;
        })
        .catch((err) => {
          console.log(err);
          setTimeout((this.overlay = false), 3000);
        });
    },
    DeleteFromCard(id) {
      this.$store.dispatch("DeleteCard", id);
    },
    AddTovar(id) {
      this.$store.dispatch("SetCard", id);
    },
    RemoveTovar(id) {
      this.$store.dispatch("RemoveCard", id);
    },
  },
};
</script>
<style scoped>
.Card_dark {
  background: #040c1b !important;
}

.tab2_title {
  font-weight: 600;
  font-size: 50px;
  line-height: 100.31%;
  text-align: center;
  letter-spacing: -0.075em;
  color: rgba(234, 234, 234, 0.9);
}

.tab2_subtitle {
  font-weight: 500;
  font-size: 33px;
  line-height: 100.31%;
  text-align: center;
  letter-spacing: 0.005em;
  color: rgba(234, 234, 234, 0.5);
}

.hr_silver {
  border: 1px solid #cecece;
}

.img_moto {
  background: #fff;
}

.all_price {
  font-weight: 600;
  font-size: 27px;
  letter-spacing: -0.02em;
  color: #040c1b;
}

.itogo {
  font-weight: 400;
  font-size: 17px;
  letter-spacing: -0.02em;
  color: #040c1b;
}

.tabs_items {
  background-color: transparent !important;
}

.btn_zakaz {
  border-radius: 9px;
  font-weight: 500;
  letter-spacing: -0.02em;
  font-size: 17px;
  text-transform: none;
  line-height: 103.34%;
  color: #f4f4f4;
}

.card_right_subtitle {
  font-weight: 400;
  font-size: 15px;
  line-height: 105.34%;
  letter-spacing: -0.02em;
  color: #040c1b;
}

.text_dop {
  font-weight: 400;
  font-size: 15px;
  line-height: 105.34%;
  text-align: center;
  letter-spacing: -0.02em;
  color: #8e8882;
}

.price_shop {
  font-weight: 600;
  font-size: 16px;
  line-height: 105.34%;
  letter-spacing: -0.02em;
  color: #ca9d2a !important;
}

.item_shop_text {
  font-weight: 600;
  font-size: 18px;
  line-height: 105.34%;
  letter-spacing: -0.02em;
  color: #040c1b;
}

.card_right {
  background: #fafafa;
  border-radius: 10px;
}

.CardPage {
  background-color: #f1f1f1;
}
</style>
<style>
.img_rotate .v-image__image {
  animation: 5s linear 0s normal none infinite running rot;
  -webkit-animation: 5s linear 0s normal none infinite running rot;
  width: 100%;
}

@keyframes rot {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@-webkit-keyframes rot {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.breadcrumbs_card {
  transition: 0.2s;
  background: #d9d9d9 !important;
  border-radius: 3px;
  transform: skewX(340deg);
  margin-top: 10px;
  margin-bottom: 10px;
}

.breadcrumbs_card .v-btn__content {
  font-weight: 400;
  text-transform: none;
  font-size: 15px;
  line-height: 18px;
  letter-spacing: 0.095em;
  color: #626772 !important;
  padding: 8px 22px;
  transform: skewX(20deg);
}

.breadcrumbs_card {
  padding: 6px !important;
}

.breadcrumbs_card:hover {
  background: #c2c1c1 !important;
  color: #343434 !important;
}

.breadcrumbs_card.v-btn--disabled {
  opacity: 0.8 !important;
}
</style>