<template>
  <div>
    <Carousel />
    <Categories />
    <HusqvarnaBikes />
    <BudRacing />
    <WaygomTyres />
    <BrandNew />
    <Parallax />
    <ContactUs />
    <FooterPage />
  </div>
</template>

<script>
import Carousel from "../components/Home/Carousel";
import Categories from "../components/Home/Categories.vue";
import HusqvarnaBikes from "../components/Home/Husqvarna_bikes.vue";
import BudRacing from "../components/Home/Bud_racing.vue";
import WaygomTyres from "../components/Home/Waygom_tyres.vue";
import BrandNew from "../components/Home/Brand_new.vue";
import Parallax from "../components/Home/Parallax.vue";
import ContactUs from "../components/Home/ContactUs.vue";
import FooterPage from "../components/FooterPage.vue";

export default {
  name: "HomeView",

  components: {
    Carousel,
    Categories,
    HusqvarnaBikes,
    BudRacing,
    WaygomTyres,
    BrandNew,
    Parallax,
    ContactUs,
    FooterPage,
  },
};
</script>
