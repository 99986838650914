<template>
  <v-container fluid>
    <v-divider v-show="getProducts.length > 1" class="mt-1 mb-6"></v-divider>
    <v-row justify="center">
      <v-col cols="12" md="10">
        <v-row justify="center" class="py-8">
          <v-slide-group v-model="slide_group" class="pa-4 slide_img" active-class="success" show-arrows>
            <v-slide-item v-for="product in getProducts" :key="product['ID']" v-show="product['ID'] != $route.params.id"
              v-slot="{ active, toggle }">
              <v-card width="200px" @click="toggle" :class="active ? '' : undefined" :href="'/Product/' +
                $route.params.name +
                '/' +
                ($route.params.subname ? $route.params.subname + '/' : '') +
                product['ID']
                " flat class="cards_products mx-2">
                <v-img max-width="100%" aspect-ratio="1.5" contain :src="getIMGPathProduct(product['Images'][0])">
                </v-img>
                <v-row v-if="product['New']" class="ma-0" justify="end">
                  <svg width="43" height="42" viewBox="0 0 43 42" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M20.0316 1.37654C20.9598 0.840641 22.1034 0.84064 23.0316 1.37654L37.8704 9.94372C38.7986 10.4796 39.3704 11.47 39.3704 12.5418V29.6762C39.3704 30.748 38.7986 31.7383 37.8704 32.2742L23.0316 40.8414C22.1034 41.3773 20.9598 41.3773 20.0316 40.8414L5.19278 32.2742C4.26458 31.7383 3.69278 30.748 3.69278 29.6762V12.5418C3.69278 11.47 4.26457 10.4796 5.19278 9.94372L20.0316 1.37654Z"
                      fill="#262626" fill-opacity="0.71" />
                    <path
                      d="M1.79913 22.609C1.26323 21.6808 1.26323 20.5372 1.79913 19.609L10.3663 4.77019C10.9022 3.84199 11.8926 3.27019 12.9644 3.27019H30.0988C31.1706 3.27019 32.1609 3.84198 32.6968 4.77019L41.264 19.609C41.7999 20.5372 41.7999 21.6808 41.264 22.609L32.6968 37.4478C32.1609 38.376 31.1706 38.9478 30.0988 38.9478H12.9644C11.8926 38.9478 10.9022 38.376 10.3663 37.4478L1.79913 22.609Z"
                      fill="#262626" fill-opacity="0.79" />
                    <path
                      d="M10.4082 15.7534H11.589L14.8834 22.3299V15.7534H16.3411V25.6406H15.175L11.8805 19.124V25.6406H10.4082V15.7534Z"
                      fill="white" />
                    <path
                      d="M16.9674 15.7534H21.8507V16.9968H18.4397V20.0978H21.2822V21.2213H18.4397V24.3972H21.8653V25.6406H16.9674V15.7534Z"
                      fill="white" />
                    <path
                      d="M22.3592 15.7534H23.8607L25.4788 22.7494L26.747 17.8806H27.8548L29.3854 22.7943L30.7411 15.7534H32.2426L30.2892 25.6406H28.9919L27.3446 20.6221L25.989 25.6406H24.6916L22.3592 15.7534Z"
                      fill="white" />
                  </svg>
                </v-row>

                <v-divider class="mt-0"></v-divider>
                <v-card-title class="products_title font_wrap pt-3 font_wrap">
                  {{
                    getLanguage == "Ru"
                      ? product["Name Ru"]
                      : product["Name Ro"]
                  }}
                </v-card-title>
                <v-card-subtitle class="products_price pt-2 d-flex font_wrap">
                  {{ product["Price"] }}
                  <v-spacer></v-spacer>
                  <v-btn @click.prevent="addToShoppingCart(product['ID'])" icon>
                    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M0 0.6C0 0.268629 0.268629 0 0.6 0H2.4C2.67532 0 2.91531 0.187378 2.98208 0.454479L3.46846 2.4H17.4C17.5785 2.4 17.7477 2.47947 17.8617 2.61682C17.9757 2.75417 18.0226 2.93514 17.9897 3.11057L16.1897 12.7106C16.1365 12.9944 15.8887 13.2 15.6 13.2H14.4H6H4.8C4.51127 13.2 4.26348 12.9944 4.21028 12.7106L2.41357 3.12816L1.93153 1.2H0.6C0.268629 1.2 0 0.931371 0 0.6ZM6 13.2C4.67452 13.2 3.6 14.2745 3.6 15.6C3.6 16.9255 4.67452 18 6 18C7.32548 18 8.4 16.9255 8.4 15.6C8.4 14.2745 7.32548 13.2 6 13.2ZM14.4 13.2C13.0745 13.2 12 14.2745 12 15.6C12 16.9255 13.0745 18 14.4 18C15.7255 18 16.8 16.9255 16.8 15.6C16.8 14.2745 15.7255 13.2 14.4 13.2ZM6 14.4C6.66274 14.4 7.2 14.9373 7.2 15.6C7.2 16.2627 6.66274 16.8 6 16.8C5.33726 16.8 4.8 16.2627 4.8 15.6C4.8 14.9373 5.33726 14.4 6 14.4ZM14.4 14.4C15.0627 14.4 15.6 14.9373 15.6 15.6C15.6 16.2627 15.0627 16.8 14.4 16.8C13.7373 16.8 13.2 16.2627 13.2 15.6C13.2 14.9373 13.7373 14.4 14.4 14.4Z"
                        fill="#A0A0A0" />
                    </svg>
                  </v-btn>
                </v-card-subtitle>
              </v-card>
            </v-slide-item>
          </v-slide-group>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "OtherProducts",
  computed: {
    ...mapGetters(["getLang", "getLanguage", "getProducts"]),
  },
  data: () => ({
    slide_group: null,
  }),
  methods: {
    addToShoppingCart(id) {
      this.$store.dispatch("SetCard", id);
    },
    getIMGPathProduct(url) {
      if (url.includes('http')) return url
      return require(`@/assets/img/products/${url}`);
    },
  }
};
</script>

<style scoped>
@media only screen and (max-width: 600px) {
  .products_price {
    zoom: 0.8;
  }
}

.products_price {
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  text-align: center;
  letter-spacing: -0.03em;
  align-items: center;
  color: #dcbb10 !important;
}

.products_title {
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  letter-spacing: -0.03em;
}

.cards_products {
  transition: 0.2s;
}

.cards_products:hover {
  transform: scale(1.03);
  background-color: #faf9f9 !important;
}

.divider_l {
  border: 1px solid #676767;
}

.title_subcat {
  font-weight: 500;
  font-size: 22px;
  line-height: 20px;
  color: #040c1b;
}
</style>