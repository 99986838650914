<template>
  <v-container fluid class="pa-0 parallaxHome">
    <v-parallax height="350" class="pa-0" dark src="../../assets/Card/1.png">
      <v-row justify="center" class="fill-height parallax_row">
        <v-col cols="10" align-self="end" class="pb-10">
          <v-row>
            <v-col cols="12" md="6">
              <span class="title_parallax fontB font_wrap">
                {{ getLang.CardPage.title }}
              </span>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-parallax>
  </v-container>
</template>
<script>
import { mapGetters } from "vuex";

export default {
  name: "ParallaxCard",
  data: () => ({}),
  computed: {
    ...mapGetters(["getLanguage", "getLang", "getCard"]),
  },
};
</script>
  
<style scoped>
.title_parallax {
  font-weight: 600;
  font-size: 42px;
  letter-spacing: -0.015em;
  color: #ffffff;
}
.parallax_row {
  background: linear-gradient(
    180deg,
    rgba(4, 12, 27, 0) 50%,
    rgba(4, 12, 27, 0.6) 100%
  );
}
</style>
