import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: '#DCBB10',
        secondary: '#040C1B',
        accent: '#8c9eff',
        error: '#b71c1c',
        background: '#fafafa',
        btnYellow: '#c4a60c',
      },
    },
  },
});
