var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-container',{staticClass:"pa-0 categories",attrs:{"fluid":""}},[_c('v-row',{staticClass:"text-center",attrs:{"justify":"center"}},[_c('v-col',{attrs:{"cols":"10"}},[_c('v-row',{staticClass:"my-5",attrs:{"justify":"center"}},[_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('v-card',{staticClass:"rounded-xl",attrs:{"href":"/category/road_bikes","color":"transparent","flat":""}},[_c('v-img',{staticClass:"cards_img",attrs:{"width":"100%","height":"100%","aspect-ratio":"1","src":require("../../assets/HomePage/Categories/1.png"),"lazy-src":require("../../assets/HomePage/Categories/lazy/1.png"),"alt":"Motocicletă de drum pe autostradă cu motociclist"}},[_c('div',{staticClass:"fill-height d-flex flex-column justify-end text-left"},[_c('v-card-title',{staticClass:"title_card fontB font_wrap"},[_vm._v(" "+_vm._s(_vm.getLang.HomePage.ThreeSubcategories.Road_bikes.title)+" ")]),_c('v-card-subtitle',{staticClass:"subtitle_card fontR"},[_c('span',{domProps:{"innerHTML":_vm._s(
                        _vm.getLang.HomePage.ThreeSubcategories.Road_bikes
                          .subtitle
                      )}})])],1)])],1)],1),_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('v-card',{staticClass:"rounded-xl",attrs:{"href":"/category/Husqvarna_bikes/Motocross","color":"transparent","flat":""}},[_c('v-img',{staticClass:"cards_img",attrs:{"width":"100%","height":"100%","aspect-ratio":"1","src":require("../../assets/HomePage/Categories/2.png"),"lazy-src":require("../../assets/HomePage/Categories/lazy/2.png"),"alt":"Motocross bike în aer, cu biker la ghidon"}},[_c('div',{staticClass:"fill-height d-flex flex-column justify-end text-left"},[_c('v-card-title',{staticClass:"title_card fontB font_wrap"},[_vm._v(" "+_vm._s(_vm.getLang.HomePage.ThreeSubcategories.Enduro_MX.title)+" ")]),_c('v-card-subtitle',{staticClass:"subtitle_card fontR"},[_c('span',{domProps:{"innerHTML":_vm._s(
                        _vm.getLang.HomePage.ThreeSubcategories.Enduro_MX.subtitle
                      )}})])],1)])],1)],1),_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('v-card',{staticClass:"rounded-xl",attrs:{"href":"/category/Bud_racing","color":"transparent","flat":""}},[_c('v-img',{staticClass:"cards_img",attrs:{"width":"100%","height":"100%","aspect-ratio":"1","src":require("../../assets/HomePage/Categories/3.png"),"lazy-src":require("../../assets/HomePage/Categories/lazy/3.png"),"alt":"Rider enduro în echipament alb pe motocicletă"}},[_c('div',{staticClass:"fill-height d-flex flex-column justify-end text-left"},[_c('v-card-title',{staticClass:"title_card fontB font_wrap"},[_vm._v(" "+_vm._s(_vm.getLang.HomePage.ThreeSubcategories.Equipment.title)+" ")]),_c('v-card-subtitle',{staticClass:"subtitle_card fontR"},[_c('span',{domProps:{"innerHTML":_vm._s(
                        _vm.getLang.HomePage.ThreeSubcategories.Equipment.subtitle
                      )}})])],1)])],1)],1)],1),_c('v-row',{staticClass:"mt-10 mb-4",attrs:{"justify":"center"}},[_c('v-col',{attrs:{"cols":"12","md":"5","align-self":"center"}},[_c('v-card',{staticClass:"card_left text-left",attrs:{"flat":"","color":"transparent"}},[_c('v-card-title',{staticClass:"left_card_title pa-0 fontB"},[_vm._v(" "+_vm._s(_vm.getLang.HomePage.Categories.Rieju_moto.title)+" ")]),_c('v-card-subtitle',{staticClass:"left_card_subtitle fontB pa-0 mt-1"},[_vm._v(" "+_vm._s(_vm.getLang.HomePage.Categories.Rieju_moto.subtitle)+" ")]),_c('span',{staticClass:"left_card_text px-0 d-flex"},[_c('v-img',{staticClass:"mt-1",attrs:{"max-width":"30px","max-height":"18px","src":require("../../assets/HomePage/Categories/5.png")}}),_c('span',{staticClass:"ml-2 fontB"},[_vm._v(" "+_vm._s(_vm.getLang.HomePage.Categories.Rieju_moto.made_in)+" ")])],1),_c('v-card-text',{staticClass:"left_card_text2 px-0 fontB"},[_c('span',{domProps:{"innerHTML":_vm._s(_vm.getLang.HomePage.Categories.Rieju_moto.text)}})]),_c('v-btn',{staticClass:"mt-3 btn_carousel fontR px-12",attrs:{"href":"/category/Rieju_bikes","color":"btnYellow","elevation":"0"}},[_vm._v(" "+_vm._s(_vm.getLang.HomePage.Categories.Rieju_moto.button)+" ")])],1)],1),_c('v-col',{attrs:{"cols":"12","md":"7","align-self":"center"}},[_c('v-img',{attrs:{"max-width":"100%","max-height":"100%","src":require("../../assets/HomePage/Categories/4.png"),"lazy-src":require("../../assets/HomePage/Categories/lazy/4.png"),"alt":"Furcă enduro motocicletă Rieju, Rider enduro în pădure pe motocicletă enduro"}})],1)],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }