<template>
  <v-container fluid class="px-0 py-6 mt-2 our_products">
    <v-row justify="center" class="text-left py-15">
      <v-col cols="10" class="">
        <v-row justify="center">
          <v-card-title class="title_partners fontB">
            {{ getLang.AboutPage.our_partners_title }}
          </v-card-title>
        </v-row>
        <v-row justify="center">
          <v-col cols="4" md="2" align-self="center">
            <v-img width="100%" src="../../assets/About/31.png"></v-img>
          </v-col>
          <v-col cols="4" md="2" align-self="center">
            <v-img width="100%" src="../../assets/About/32.png"></v-img>
          </v-col>
          <v-col cols="4" md="2" align-self="center">
            <v-img width="100%" src="../../assets/About/33.png"></v-img>
          </v-col>
          <v-col cols="4" md="2" align-self="center">
            <v-img width="100%" src="../../assets/About/34.png"></v-img>
          </v-col>
          <v-col cols="4" md="2" align-self="center" class="px-6">
            <v-img width="90%" src="../../assets/About/35.png"></v-img>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import { mapGetters } from "vuex";

export default {
  name: "ourPartners",
  computed: {
    ...mapGetters(["getLang"]),
  },
};
</script>

<style scoped>
.title_partners {
  font-weight: 600;
  font-size: 36px;
  line-height: 42px;
  letter-spacing: -0.05em;
  color: #040c1b;
}
</style>