import Vue from "vue";
import App from "./App.vue";
import store from "./store";
import router from "./router";
import vuetify from "./plugins/vuetify";
import firebaseConfig from "./config/firebase.js";
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getAuth } from "firebase/auth";
import { getAnalytics } from "firebase/analytics";
import { getStorage } from "firebase/storage";
import VueMeta from 'vue-meta'
const firebaseApp = initializeApp(firebaseConfig);
Vue.$firestore = getFirestore(firebaseApp);
Vue.$auth = getAuth(firebaseApp);
Vue.$analytics = getAnalytics(firebaseApp);
Vue.$storage = getStorage(firebaseApp);


Vue.config.productionTip = false;
Vue.use(VueMeta)

new Vue({
  store,
  router,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
