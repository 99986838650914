<template>
  <v-container fluid class="pa-0 parallaxHome pb-15">
    <v-parallax height="450" class="pa-0" dark src="../../assets/About/1.png">
      <v-row justify="center" class="fill-height parallax_row">
        <v-col cols="10" align-self="end" class="pb-10">
          <v-row justify="center">
            <v-col cols="12" md="6">
              <v-img width="100%" src="../../assets/logo_white.png"></v-img>
              <!-- <svg
                width="100%"
                viewBox="0 0 450 43"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g filter="url(#filter0_b_232_830)">
                  <path
                    d="M60.035 8.53281L58.4552 14.1094C57.753 16.5281 56.2734 18.2302 54.0165 19.2156C52.4115 19.9323 49.8035 20.4026 46.1924 20.6266V20.7609C49.4524 21.1641 51.6341 21.5672 52.7375 21.9703C54.3425 22.5974 55.145 23.6276 55.145 25.0609C55.145 25.5536 55.0697 26.1135 54.9193 26.7406L52.8128 34.4672C51.258 40.1557 46.3178 43 37.9921 43H0L12.0371 0H50.0292C57.0007 0 60.4864 1.92604 60.4864 5.77813C60.4864 6.58438 60.336 7.50261 60.035 8.53281ZM41.4528 9.40625C41.5531 9.00313 41.6032 8.66719 41.6032 8.39844C41.6032 7.5474 40.876 7.12187 39.4215 7.12187H28.3624L25.5788 17.2H36.6379C38.3933 17.2 39.4967 16.3938 39.9481 14.7812L41.4528 9.40625ZM36.6379 26.6734C36.7382 26.2255 36.7884 25.8672 36.7884 25.5984C36.7884 24.7474 36.0611 24.3219 34.6067 24.3219H23.5476L20.3126 35.8781H31.3717C33.1271 35.8781 34.2305 35.0943 34.6819 33.5266L36.6379 26.6734Z"
                    fill="#EAEAEA"
                  />
                  <path
                    d="M83.1712 0L71.1341 43H53.3794L65.4165 0H83.1712Z"
                    fill="#EAEAEA"
                  />
                  <path
                    d="M125.823 22.7094L120.181 43H103.253L104.231 39.5062H104.081C102.877 41.8354 100.319 43 96.4073 43H83.016C77.5993 43 74.891 41.2083 74.891 37.625C74.891 36.55 75.1167 35.251 75.5681 33.7281L76.3956 30.8391C77.3486 27.5693 78.7529 25.2177 80.6086 23.7844C82.5145 22.351 85.2228 21.6344 88.7336 21.6344H108.369L108.971 19.6188C109.021 19.4396 109.046 19.2604 109.046 19.0813C109.046 18.0958 108.294 17.6031 106.789 17.6031H81.7371L83.4674 11.4219H117.322C123.491 11.4219 126.575 13.6391 126.575 18.0734C126.575 19.3724 126.325 20.9177 125.823 22.7094ZM106.639 27.8156H98.8147C97.3101 27.8156 96.2568 28.0172 95.655 28.4203C95.0531 28.8234 94.6017 29.4953 94.3008 30.4359L93.1723 34.2656C93.072 34.6687 93.0219 35.0047 93.0219 35.2734C93.0219 36.3036 94.05 36.8188 96.1064 36.8188H100.921C102.275 36.8188 103.228 36.5724 103.78 36.0797C104.332 35.5422 104.808 34.512 105.209 32.9891L106.639 27.8156Z"
                    fill="#EAEAEA"
                  />
                  <path
                    d="M170.455 11.4219L168.725 17.6031H149.842C147.384 17.6031 145.855 18.6781 145.253 20.8281L141.717 33.5938C141.617 33.9969 141.566 34.3552 141.566 34.6688C141.566 36.1021 142.544 36.8188 144.5 36.8188H163.384L161.653 43H132.162C126.395 43 123.511 40.8724 123.511 36.6172C123.511 35.6766 123.661 34.6688 123.962 33.5938L127.498 20.8281C129.253 14.5573 133.742 11.4219 140.965 11.4219H170.455Z"
                    fill="#EAEAEA"
                  />
                  <path
                    d="M216.185 11.4219L203.621 18.8125L212.574 43H192.035L187.973 28.2859L184.362 30.4359L180.826 43H163.071L175.108 0H192.863L186.543 22.5078L204.9 11.4219H216.185Z"
                    fill="#EAEAEA"
                  />
                  <path
                    d="M315.243 0L303.206 43H284.849L294.328 9.1375H294.178L271.383 43H258.668L255.208 9.1375H255.057L245.578 43H237.603L249.64 0H273.113L275.445 24.3891H275.595L292.222 0H315.243Z"
                    fill="#EAEAEA"
                  />
                  <path
                    d="M359.887 20.5594L356.201 33.8625C355.348 36.9083 354.044 39.1479 352.289 40.5812C350.282 42.1937 347.474 43 343.863 43H315.274C310.058 43 307.45 41.2083 307.45 37.625C307.45 36.5948 307.651 35.3406 308.052 33.8625L311.739 20.5594C312.591 17.5135 313.895 15.274 315.651 13.8406C317.657 12.2281 320.465 11.4219 324.077 11.4219H352.665C357.881 11.4219 360.489 13.2135 360.489 16.7969C360.489 17.8271 360.288 19.0813 359.887 20.5594ZM342.358 19.9547C342.458 19.5964 342.508 19.3052 342.508 19.0813C342.508 18.0958 341.831 17.6031 340.477 17.6031H332.803C331.199 17.6031 330.17 18.387 329.719 19.9547L325.656 34.4672C325.556 34.8255 325.506 35.1391 325.506 35.4078C325.506 36.3484 326.158 36.8188 327.462 36.8188H335.136C336.791 36.8188 337.844 36.0349 338.295 34.4672L342.358 19.9547Z"
                    fill="#EAEAEA"
                  />
                  <path
                    d="M401.43 11.4219L399.699 17.6031H385.556L380.891 34.4672C380.791 34.8255 380.741 35.1391 380.741 35.4078C380.741 36.3484 381.518 36.8188 383.073 36.8188H396.163L394.433 43H370.359C368.002 43 366.146 42.4401 364.792 41.3203C363.488 40.2005 362.836 38.6776 362.836 36.7516C362.836 35.8557 362.961 34.9375 363.212 33.9969L367.801 17.6031H360.353L362.083 11.4219H369.531L371.487 4.56875H389.242L387.286 11.4219H401.43Z"
                    fill="#EAEAEA"
                  />
                  <path
                    d="M449.398 20.5594L445.712 33.8625C444.859 36.9083 443.555 39.1479 441.8 40.5812C439.794 42.1937 436.985 43 433.374 43H404.786C399.57 43 396.962 41.2083 396.962 37.625C396.962 36.5948 397.162 35.3406 397.563 33.8625L401.25 20.5594C402.102 17.5135 403.406 15.274 405.162 13.8406C407.168 12.2281 409.977 11.4219 413.588 11.4219H442.176C447.392 11.4219 450 13.2135 450 16.7969C450 17.8271 449.799 19.0813 449.398 20.5594ZM431.869 19.9547C431.969 19.5964 432.02 19.3052 432.02 19.0813C432.02 18.0958 431.342 17.6031 429.988 17.6031H422.315C420.71 17.6031 419.682 18.387 419.23 19.9547L415.168 34.4672C415.067 34.8255 415.017 35.1391 415.017 35.4078C415.017 36.3484 415.669 36.8188 416.973 36.8188H424.647C426.302 36.8188 427.355 36.0349 427.807 34.4672L431.869 19.9547Z"
                    fill="#EAEAEA"
                  />
                </g>
                <defs>
                  <filter
                    id="filter0_b_232_830"
                    x="-46.3506"
                    y="-46.3506"
                    width="542.701"
                    height="135.701"
                    filterUnits="userSpaceOnUse"
                    color-interpolation-filters="sRGB"
                  >
                    <feFlood flood-opacity="0" result="BackgroundImageFix" />
                    <feGaussianBlur
                      in="BackgroundImageFix"
                      stdDeviation="23.1753"
                    />
                    <feComposite
                      in2="SourceAlpha"
                      operator="in"
                      result="effect1_backgroundBlur_232_830"
                    />
                    <feBlend
                      mode="normal"
                      in="SourceGraphic"
                      in2="effect1_backgroundBlur_232_830"
                      result="shape"
                    />
                  </filter>
                </defs>
              </svg> -->
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-parallax>
  </v-container>
</template>
    
<script>
export default {
  name: "ParallaxAbout",
  data: () => ({}),
};
</script>
  
<style scoped>
.parallax_row {
  background: linear-gradient(
    180deg,
    rgba(4, 12, 27, 0) 50%,
    rgba(4, 12, 27, 0.6) 100%
  );
}
</style>