<template>
  <v-container fluid class="pa-0 categories my-15">
    <v-row justify="center" class="text-start">
      <v-col cols="10" class="">
        <v-row>
          <v-col cols="12" md="3">
            <v-divider class="divider"></v-divider>
            <v-card-title class="title_about fontB px-0">
              {{ getLang.AboutPage.about_title }}
            </v-card-title>
            <v-card-subtitle class="subtitle_about px-0 mt-4 pb-1">
              +589
            </v-card-subtitle>
            <v-card-text class="text_about px-0">
              <span v-html="getLang.AboutPage.about_count_clients"></span>
            </v-card-text>

            <v-card-subtitle class="subtitle_about px-0 mt-3 pb-1">
              +12
            </v-card-subtitle>
            <v-card-text class="text_about px-0">
              <span v-html="getLang.AboutPage.about_years"></span>
            </v-card-text>
          </v-col>
          <v-col cols="12" md="6" lg="5" offset-lg="1">
            <v-card-title class="title_about_text pa-0 font_wrap">
              <span v-html="getLang.AboutPage.about_text"></span>
            </v-card-title>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "AboutText",
  computed: {
    ...mapGetters(["getLang"]),
  },
};
</script>

<style scoped>
.title_about_text {
  font-weight: 500;
  font-size: 15px;
  line-height: 145.84%;
  text-align: justify;
  letter-spacing: -0.03em;
  color: #040c1b;
}
.text_about {
  font-weight: 600;
  font-size: 17px;
  line-height: 20px;
  letter-spacing: -0.05em;
  color: #040c1b;
}
.subtitle_about {
  font-weight: 600;
  font-size: 24px;
  line-height: 30px;
  letter-spacing: -0.05em;
  color: #dcbb10;
}
.title_about {
  font-weight: 600;
  font-size: 42px;
  line-height: 50px;
  letter-spacing: -0.05em;
  color: #000000;
}
.divider {
  border: 1px solid #040c1b;
}
</style>