var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{staticClass:"my-4 px-4",attrs:{"fluid":""}},[_c('v-row',{staticClass:"text-center ma-0 tovarBlock",attrs:{"justify":"center"}},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-row',{staticClass:"mt-10 mb-4 px-md-8",attrs:{"justify":"center"}},[(_vm.tovar['Images'])?_c('v-col',{staticStyle:{"position":"relative"},attrs:{"cols":"12","md":"6","align-self":"center"}},[_c('v-img',{staticClass:"img_big",attrs:{"max-width":"100%","aspect-ratio":"1.5","max-height":"100%","contain":"","src":_vm.slide_group ? _vm.getIMGPathProduct(_vm.tovar['Images'][_vm.slide_group]) : _vm.getIMGPathProduct(_vm.tovar['Images'][0]),"alt":_vm.tovar.alt,"title":_vm.tovar.title}}),_c('v-slide-group',{staticClass:"pa-4 slide_img",attrs:{"active-class":"success","show-arrows":""},model:{value:(_vm.slide_group),callback:function ($$v) {_vm.slide_group=$$v},expression:"slide_group"}},_vm._l((_vm.tovar['Images']),function(n){return _c('v-slide-item',{key:n,scopedSlots:_vm._u([{key:"default",fn:function({ active, toggle }){return [(n)?_c('v-img',{staticClass:"ma-2 imgs",attrs:{"src":_vm.getIMGPathProduct(n),"max-width":"70px","contain":""},on:{"click":toggle}},[_c('v-row',{staticClass:"fill-height row_img ma-0",class:active ? 'active_image' : undefined})],1):_vm._e()]}}],null,true)})}),1)],1):_vm._e(),_c('v-col',{attrs:{"cols":"12","md":"6","align-self":"center"}},[_c('v-card',{staticClass:"card_left text-left",attrs:{"flat":"","color":"transparent"}},[_c('v-card-title',{staticClass:"card_title pa-0 fontB font_wrap"},[_c('span',{domProps:{"innerHTML":_vm._s(_vm.getLanguage == 'Ru' ? _vm.tovar['Name Ru'] : _vm.tovar['Name Ro']
                )}})]),(_vm.Categorie)?_c('v-card-subtitle',{staticClass:"card_brand fontR pa-0 my-4 font_wrap"},[_c('span',{staticClass:"card_brand_1",domProps:{"innerHTML":_vm._s(_vm.getLanguage == 'Ru' ? 'Бренд: ' : 'Marca: ')}}),(_vm.Categorie[0])?_c('span',{domProps:{"innerHTML":_vm._s(_vm.getLanguage == 'Ru'
                ? _vm.Categorie[0]['Name Ru']
                : _vm.Categorie[0]['Name Ro']
                )}}):_vm._e()]):_vm._e(),_c('v-card-subtitle',{staticClass:"card_price fontR pa-0 my-4 font_wrap"},[_c('span',{domProps:{"innerHTML":_vm._s(_vm.getLanguage == 'Ru' ? 'Цена: ' : 'Preț: ')}}),_c('span',{domProps:{"innerHTML":_vm._s(_vm.tovar['Price'])}})]),_c('v-divider'),_c('v-card-subtitle',{staticClass:"card_subtitle fontR pa-0 mt-1 font_wrap"},[_c('span',{domProps:{"innerHTML":_vm._s(_vm.getLanguage == 'Ru'
                ? _vm.tovar['Description Ru']
                : _vm.tovar['Description Ro']
                )}})]),_c('v-card-actions',{staticClass:"px-0 mt-10"},[_c('v-btn',{staticClass:"btn_buy fontR px-12",attrs:{"color":"primary","elevation":"0"},on:{"click":function($event){return _vm.addToCardAndBuy(_vm.$route.params.id)}}},[_vm._v(" "+_vm._s(_vm.getLanguage == 'Ru' ? 'Оформить заявку' : 'Lasa o comanda')+" ")]),_c('v-btn',{staticClass:"btn_buy_2 fontR px-5",attrs:{"icon":"","outlined":"","elevation":"0"},on:{"click":function($event){return _vm.addToCard(_vm.$route.params.id)}}},[_c('svg',{attrs:{"width":"14","viewBox":"0 0 14 16","fill":"none","xmlns":"http://www.w3.org/2000/svg"}},[_c('path',{attrs:{"d":"M7 1C8.38071 1 9.5 2.11929 9.5 3.5V4H4.5V3.5C4.5 2.11929 5.61929 1 7 1ZM10.5 4V3.5C10.5 1.567 8.933 0 7 0C5.067 0 3.5 1.567 3.5 3.5V4H0V14C0 15.1046 0.895431 16 2 16H12C13.1046 16 14 15.1046 14 14V4H10.5Z","fill":"#DCBB10"}})])])],1)],1)],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }