import { render, staticRenderFns } from "./ourProducts.vue?vue&type=template&id=5c421180&scoped=true&"
import script from "./ourProducts.vue?vue&type=script&lang=js&"
export * from "./ourProducts.vue?vue&type=script&lang=js&"
import style0 from "./ourProducts.vue?vue&type=style&index=0&id=5c421180&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5c421180",
  null
  
)

export default component.exports