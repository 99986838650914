<template>
  <v-container fluid class="pa-0 container">
    <v-row justify="center" class="text-center">
      <v-col cols="10">
        <v-row justify="center" class="mt-10 mb-4">
          <v-col cols="12" md="5" align-self="center">
            <v-img
              max-width="100%"
              max-height="100%"
              src="../../assets/HomePage/Waygom_tyres/1.png"
              lazy-src="../../assets/HomePage/Waygom_tyres/1_lazy.png"
              alt="Roată de motocicletă, Anvelope pentru motociclete Waygom, Anvelope pentru motociclete de drum pe fundal de deșert"
            >
            </v-img>
          </v-col>
          <v-col cols="12" md="6" offset-md="1" align-self="center">
            <v-card flat color="transparent" class="card_left text-right">
              <v-card-title class="card_title pa-0 fontB justify-end font_wrap">
                {{ getLang.HomePage.Categories.Waygom_tyres.title }}
              </v-card-title>
              <v-card-subtitle class="card_subtitle font_wrap fontB pa-0 mt-1">
                {{ getLang.HomePage.Categories.Waygom_tyres.subtitle }}
              </v-card-subtitle>
              <span class="card_text px-0 d-flex justify-end">
                <span class="mr-2 fontB">
                  {{ getLang.HomePage.Categories.Waygom_tyres.made_in }}
                </span>
                <v-img
                  max-width="30px"
                  max-height="18px"
                  class="mt-1"
                  src="../../assets/HomePage/Waygom_tyres/2.png"
                >
                </v-img>
              </span>
              <v-card-text class="card_text2 px-0 fontB">
                <span v-html="getLang.HomePage.Categories.Waygom_tyres.text"></span>
              </v-card-text>
              <v-btn
                href="/category/waygom"
                class="mt-3 btn_carousel fontR px-12"
                color="btnYellow"
                elevation="0"
              >
                {{ getLang.HomePage.Categories.Waygom_tyres.button }}
              </v-btn>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "WaygomTyres",
  computed: {
    ...mapGetters(["getLang"]),
  },
};
</script>
  
  <style scoped>
.btn_carousel {
  text-shadow: 0 0 3px #574A0A;
  color: #fff;
  border-radius: 0px;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  letter-spacing: -0.02em;
}
.card_text2 {
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  text-align: right;
  letter-spacing: -0.03em;
  color: #eaeaea !important;
}
.card_text {
  font-weight: 600;
  font-size: 16px;
  line-height: 28px;
  letter-spacing: -0.02em;
  color: rgba(234, 234, 234, 0.5);
}
.card_subtitle {
  font-weight: 600;
  font-size: 32px;
  line-height: 45px;
  text-align: right;
  letter-spacing: -0.02em;
  color: #dcbb10 !important;
}
.card_title {
  font-weight: 600;
  font-size: 52px;
  line-height: 65px;
  text-align: right;
  letter-spacing: -0.02em;
  color: #eaeaea;
}
.container {
  background-color: #040c1b;
}
</style>