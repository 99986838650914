<template>
  <v-container fluid class="pa-0 parallaxHome pb-6">
    <v-parallax height="350" class="pa-0" dark src="../../assets/Product/1.png">
    </v-parallax>
  </v-container>
</template>
    
<script>
export default {
  name: "ParallaxProduct",
  data: () => ({}),
};
</script>
  
<style scoped>
</style>