<template>
  <div>
    <v-container id="Contacts" fluid class="pa-0">
      <v-row justify="center" class="text-center py-15">
        <v-col cols="10" class="">
          <v-row justify="center" class="mt-10 mb-4">
            <v-col cols="12" md="6" align-self="center">
              <v-card flat color="transparent" class="card_left text-left">
                <v-card-title class="card_title pa-0 fontB font_wrap">
                  {{ getLang.Contact_Us.title }}
                </v-card-title>
                <v-row justify="start" class="mt-4 mb-4">
                  <v-col cols="12" md="8" lg="7" align-self="center">
                    <v-text-field
                      v-model="fullname"
                      color="#152547"
                      class="input_contactus"
                      label="Full Name"
                    ></v-text-field>
                    <v-text-field
                      v-model="email"
                      color="#152547"
                      class="input_contactus"
                      label="E-mail"
                      :rules="getLanguage == 'Ru' ? emailRulesRu : emailRulesRo"
                    ></v-text-field>
                    <v-text-field
                      v-model="message"
                      color="#152547"
                      class="input_contactus"
                      label="Message"
                    ></v-text-field>
                    <v-btn
                      @click="SendMessage()"
                      color="#152547"
                      class="button_contsctus mt-4 fontR px-11 py-7"
                      x-large
                    >
                      {{ getLang.Contact_Us.button }}
                    </v-btn>
                  </v-col>
                </v-row>
                <v-card-title class="contact_title fontB px-0">
                  {{ getLang.Contact_Us.contact }}
                </v-card-title>
                <v-card-subtitle class="phone_number fontR px-0 mt-1">
                  +373 69 144 616<br/>
                  +373 69 114 788<br/>
                  +373 79 101 030<br/>
                  +373 61 161 083<br/>
                </v-card-subtitle>
              </v-card>
            </v-col>
            <v-col
              cols="12"
              md="5"
              align-self="center"
              style="position: relative"
            >
              <v-img
                class="img_absolute"
                max-width="100%"
                aspect-ratio="1"
                max-height="100%"
                src="../../assets/HomePage/ContactUs/1.png"
                alt="Motocicliști pe motociclete Husqvarna în pădure"
              >
              </v-img>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
    <v-overlay :value="overlay">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
  </div>
</template>
    
<script>
import { mapGetters } from "vuex";
import axios from "axios";

export default {
  name: "ContactUs",
  computed: {
    ...mapGetters(["getLang", "getLanguage"]),
  },
  data: () => ({
    overlay: false,
    fullname: "",
    email: "",
    message: "",
    emailRulesRu: [
      (v) => !!v || "E-mail обьязательный",
      (v) => /.+@.+\..+/.test(v) || "E-mail должен быть действительный",
    ],
    emailRulesRo: [
      (v) => !!v || "E-mail obligatoriu",
      (v) => /.+@.+\..+/.test(v) || "E-mail trebuie să fie valabil",
    ],
  }),
  methods: {
    SendMessage() {
      this.overlay = true;
      axios
        .post("/SendMessage.php", {
          fullname: this.fullname,
          email: this.email,
          message: this.message,
        })
        .then(() => (this.overlay = false))
        .catch((err) => {
          console.log(err);
          setTimeout(this.overlay = false, 3000);
        });
    },
  },
};
</script>
<style>
.input_contactus.theme--light.v-text-field
  > .v-input__control
  > .v-input__slot:before {
  border: 1px solid #152547 !important;
}
</style>
<style scoped>
.phone_number {
  font-weight: 400;
  font-size: 28px;
  line-height: 120%;
  color: #152547;
}
.contact_title {
  font-weight: 500;
  font-size: 28px;
  line-height: 120%;
  color: #152547;
}
.button_contsctus {
  border-radius: 15px;
  font-weight: 500;
  font-size: 20px;
  line-height: 120%;
  color: #ffffff;
  letter-spacing: -0.02em;
  text-transform: none;
}
@media only screen and (max-width: 864px) {
  .img_absolute {
    right: 0%;
  }
}
@media only screen and (min-width: 864px) {
  .img_absolute {
    right: -15%;
    transform: scale(1.3);
  }
}

.input_contactus {
}
.card_title {
  font-weight: 600;
  font-size: 50px;
  line-height: 100%;
  letter-spacing: -0.02em;
  color: #040c1b;
}
.card_subtitle {
  font-weight: 400;
  font-size: 20px;
  line-height: 30px;
  letter-spacing: -0.05em;
  color: #040c1b !important;
}
</style>