import Vue from "vue";
import VueRouter from "vue-router";
import HomeView from "../views/HomeView.vue";
import About from "../views/AboutPage.vue";
import Terms from "../views/TermsPage.vue";
import CategoryPage from "../views/CategoryPage.vue";
import Product from "../views/Product.vue";
import Card from "../views/CardPage.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "home",
    component: HomeView,
  },
  {
    path: "/about",
    name: "aboutPage",
    component: About,
  },
  {
    path: "/terms/:id?",
    name: "terms",
    component: Terms,
  },
  {
    path: "/category/:name/:subname?",
    name: "categoryPage",
    component: CategoryPage,
  },
  {
    path: "/product/:name/:subname?/:id",
    name: "ProductPage",
    component: Product,
  },
  {
    path: "/card",
    name: "CardPage",
    component: Card,
  },
];

const router = new VueRouter({
  mode: "history",
  routes,
});

export default router;
