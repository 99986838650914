<template>
  <v-app>
    <!-- <div class="row line_black">
      <p>SALE: Toate produsele de la categoria echipament cu reducere 10%</p>
    </div> -->
    <!--<div id="preloader">
      <v-img class="img_preloader"></v-img>
    </div>-->
    <v-app-bar app color="secondary" class="app_bar">
      <v-row justify="center" class="ma-0">
        <v-col cols="12" md="11" lg="10" class="pa-0">
          <v-row class="d-flex align-center" style="flex-flow: nowrap">
            <v-app-bar-nav-icon @click="drawer = true" class="d-md-none">
              <svg width="21" viewBox="0 0 21 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M2 2H19M2 9H19M2 16H19" stroke="#EAEAEA" stroke-width="4" stroke-linecap="round" />
              </svg>
            </v-app-bar-nav-icon>
            <v-menu v-model="menu" open-on-hover offset-y bottom close-on-click transition="scale-transition">
              <template v-slot:activator="{ on, attrs }">
                <v-app-bar-nav-icon class="d-none d-md-block rounded-0" @click="menu = true" v-bind="attrs" v-on="on"
                  aria-label="Button Menu Categories">
                  <svg width="21" viewBox="0 0 21 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M2 2H19M2 9H19M2 16H19" stroke="#EAEAEA" stroke-width="4" stroke-linecap="round" />
                  </svg>
                </v-app-bar-nav-icon>
              </template>

              <v-card class="menu_card">
                <v-row justify="center">
                  <v-col cols="5">
                    <v-list flat v-model="selectedItem" class="list_first_menu">
                      <v-subheader class="categ_tovarov fontB">
                        {{ getLang.header.categ_tovars }}
                      </v-subheader>
                      <v-list-item v-for="(item, i) in CategoriesJSON.filter(
                        (categ) =>
                          categ['Subcategories (names code)'] != '' ||
                          categ['Name code'] == 'Waygom' ||
                          categ['Name code'] == 'Nils'
                      )" :key="i + 'categs_tovars'" class="py-0" :class="item['Name code'] == selectedItem_name
                        ? 'grey lighten-2'
                        : ''
                        " :href="'/category/' + selectedItem_name" @mouseover="
                          (selectedItem = item['Subcategories (names code)']),
                          (selectedItem_name = item['Name code'])
                          ">
                        <v-list-item-content class="py-0">
                          <v-list-item-title class="title_careg d-flex">
                            {{
                              getLanguage == "Ru"
                                ? item["Name Ru"]
                                : item["Name Ro"]
                            }}
                            <v-spacer></v-spacer>
                            <v-icon>mdi-chevron-right</v-icon>
                          </v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                    </v-list>
                  </v-col>
                  <v-col cols="7">
                    <v-list flat>
                      <v-subheader class="categ_tovarov fontB">
                        {{ getLang.header.subcateg_tovars }}
                      </v-subheader>
                      <v-list-item v-for="(item, i) in CategoriesJSON.filter((categ) =>
                        selectedItem.includes(categ['Name code'])
                      )" :key="i + 'subcateg'" class="py-0 subtitle_careg_link" :href="'/category/' +
                        selectedItem_name +
                        '/' +
                        item['Name code']
                        ">
                        <v-list-item-content class="py-0">
                          <v-list-item-title class="subtitle_careg fontR">
                            {{
                              getLanguage == "Ru"
                                ? item["Name Ru"]
                                : item["Name Ro"]
                            }}
                          </v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                    </v-list>
                  </v-col>
                </v-row>
              </v-card>
            </v-menu>

            <v-btn href="/" text class="title_header ml-md-3 fontB d-none d-sm-flex">
              {{ getLang.header.Button_Home }}
            </v-btn>
            <v-btn href="/#Contacts" text class="title_header ml-md-3 fontB d-none d-sm-flex">
              {{ getLang.header.Button_Contacts }}
            </v-btn>
            <v-btn href="/about" text class="title_header ml-md-3 fontB d-none d-sm-flex">
              {{ getLang.header.Button_About }}
            </v-btn>

            <v-spacer></v-spacer>

            <div class="d-flex align-center">
              <a href="/">
                <v-img alt="logo PRO Enduro" class="shrink pl-8 mr-sm-8 mr-md-15" contain src="./assets/logo_white.png"
                  transition="scale-transition" max-width="220px" max-height="50px" />
              </a>
            </div>

            <v-spacer></v-spacer>

            <v-menu offset-y rounded="b-xl" transition="slide-y-transition">
              <template v-slot:activator="{ on, attrs }">
                <v-btn color="transparent" elevation="0" class="title_header fontB pl-0 ml-sm-16 pr-0 pr-sm-3"
                  v-bind="attrs" v-on="on">
                  {{ getLanguage }}
                  <v-icon size="26">mdi-chevron-down</v-icon>
                </v-btn>
              </template>
              <v-list>
                <v-list-item @click="changeLang('Ro')">
                  <v-list-item-title>Ro</v-list-item-title>
                </v-list-item>
                <v-list-item @click="changeLang('Ru')">
                  <v-list-item-title>Ru</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
            <v-btn href="/about#contacte" text class="d-none d-sm-flex" aria-label="open the contact page">
              <svg width="25" height="26" viewBox="0 0 25 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M2.94496 0.830498C4.10451 -0.375438 6.01796 -0.251545 7.02474 1.09466L9.8274 4.84222C10.3417 5.52994 10.5235 6.42539 10.3203 7.27062L9.46518 10.8278C9.37491 11.2034 9.48071 11.6007 9.74391 11.8744L13.5823 15.8663C13.8455 16.1401 14.2275 16.2501 14.5886 16.1562L18.009 15.2669C18.8217 15.0556 19.6828 15.2446 20.344 15.7795L23.9474 18.6943C25.2419 19.7413 25.361 21.7313 24.2014 22.9372L22.5859 24.6174C21.4301 25.8194 19.7014 26.3472 18.0902 25.7581C14.0899 24.2955 10.335 21.9008 7.13826 18.5762C3.94156 15.2516 1.63898 11.3465 0.232559 7.18615C-0.333879 5.51058 0.173641 3.71267 1.32945 2.51062L2.94496 0.830498Z"
                  fill="#EAEAEA" />
              </svg>
            </v-btn>
            <v-btn small text class="pl-0 pl-sm-3" @click.prevent="shops = true" aria-label="Button product cart">
              <div class="tovar_nr" v-if="productsJSON && productsJSON.length > 0">
                {{ productsJSON ? Count : 0 }}
              </div>
              <svg width="22" height="24" viewBox="0 0 22 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M18.7585 0H3.24149C1.45126 0 0 1.45126 0 3.24149V20.7585C0 22.5487 1.45126 24 3.24149 24H18.7585C20.5487 24 22 22.5487 22 20.7585V3.24149C22 1.45126 20.5487 0 18.7585 0Z"
                  fill="#EAEAEA" />
              </svg>
            </v-btn>
          </v-row>
        </v-col>
      </v-row>
    </v-app-bar>
    <v-navigation-drawer v-model="drawer" app temporary class="drawer">
      <v-list-item class="justify-end pr-5">
        <v-btn @click="drawer = !drawer" icon x-large>
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-list-item>
      <v-list-item class="">
        <v-btn href="/" text class="title_header pl-0 ml-md-3 fontB black--text">
          {{ getLang.header.Button_Home }}
        </v-btn>
      </v-list-item>
      <v-list-item class="">
        <v-btn href="/#Contacts" text class="title_header pl-0 ml-md-3 fontB black--text">
          {{ getLang.header.Button_Contacts }}
        </v-btn>
      </v-list-item>
      <v-list-item class="">
        <v-btn href="/about" text class="title_header pl-0 ml-md-3 fontB black--text">
          {{ getLang.header.Button_About }}
        </v-btn>
      </v-list-item>
      <v-divider class="yellowDivider"></v-divider>
      <v-list dense v-for="(item, i) in CategoriesJSON.filter(
        (categ) => categ['Subcategories (names code)'] != ''
      )" :key="i">
        <v-subheader class="title_drawer fontB pl-4">
          {{ getLanguage == "Ru" ? item["Name Ru"] : item["Name Ro"] }}
        </v-subheader>
        <v-list-item class="item_drawer fontB" v-for="subitem in CategoriesJSON.filter((categ) =>
          item['Subcategories (names code)'].includes(categ['Name code'])
        )" :key="subitem['Name code']" :href="'/category/' + item['Name code'] + '/' + subitem['Name code']" link>
          <v-list-item-content>
            <v-list-item-title class="item_drawer_text">
              {{
                getLanguage == "Ru" ? subitem["Name Ru"] : subitem["Name Ro"]
              }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-divider class="yellowDivider"></v-divider>
      </v-list>
      <br />
      <br />
      <br />
      <br />
    </v-navigation-drawer>
    <v-navigation-drawer v-model="shops" app temporary class="shopCard" right>
      <v-list-item class="justify-end">
        <v-btn @click="shops = !shops" icon class="white--text">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-list-item>
      <v-card-title class="fontB justify-center shop-text">
        {{ getLang.Card.title }}
      </v-card-title>
      <v-divider class="silverDivider"></v-divider>
      <div v-if="getCard" class="card_content">
        <v-list v-for="(item, i) in productsJSON" :key="i">
          <v-list-item class="item_drawer fontB" link>
            <v-row justify="center" class="ma-0">
              <v-col v-if="item['Images']" cols="5" align-self="center">
                <v-img class="rounded-lg img_moto" width="100%" aspect-ratio="1.5" contain :src="typeof item['Images'] == 'string'
                  ? getIMGPathProduct(item['Images'].split(',')[0])
                  : getIMGPathProduct(item['Images'][0])
                  "></v-img>
              </v-col>
              <v-col cols="7">
                <v-list-item-content class="pt-0">
                  <v-list-item-title class="item_shop_text pt-0 font_wrap d-flex">
                    {{
                      getLanguage == "Ru" ? item["Name Ru"] : item["Name Ro"]
                    }}
                  </v-list-item-title>
                  <v-list-item-subtitle class="price_shop">
                    {{ item["Price"] }}
                  </v-list-item-subtitle>
                  <v-row class="mx-0 mt-2">
                    <v-col cols="7" class="d-flex pa-0 card_shop_actions" align-self="center">
                      <v-btn @click="RemoveTovar(item['ID'])" color="white" icon class="d-flex align-center">
                        <v-icon>mdi-minus</v-icon>
                      </v-btn>
                      <span class="d-flex align-center white--text kolicestvo_tovar fontB">
                        {{ item.kolicestvo_tovar }}
                      </span>
                      <v-btn @click="AddTovar(item['ID'])" color="white" icon class="d-flex align-center">
                        <v-icon>mdi-plus</v-icon>
                      </v-btn>
                    </v-col>
                    <v-col cols="4" offset="1" class="pa-1">
                      <v-btn @click="DeleteFromCard(item['ID'])" icon color="primary">
                        <svg width="14" height="16" viewBox="0 0 14 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path
                            d="M12.1809 5.06077V13.88C12.1809 14.9632 11.3041 15.84 10.2209 15.84H3.3601C2.2769 15.84 1.4001 14.9632 1.4001 13.88V5.06077C0.859299 5.06077 0.420898 4.62237 0.420898 4.07997V3.09917C0.420898 2.55837 0.859299 2.11997 1.4001 2.11997H4.3409V1.14077C4.3409 0.599973 4.7793 0.159973 5.3201 0.159973H8.2593C8.8001 0.159973 9.2401 0.598373 9.2401 1.14077V2.11997H12.1809C12.7217 2.11997 13.1617 2.55837 13.1617 3.09917V4.07997C13.1601 4.62077 12.7217 5.06077 12.1809 5.06077ZM2.3809 5.06077V13.88C2.3809 14.4208 2.8193 14.8608 3.3601 14.8608H10.2193C10.7601 14.8608 11.1985 14.4224 11.1985 13.88V5.06077C10.6497 5.06077 3.0545 5.06077 2.3809 5.06077ZM5.3201 2.11997V1.63037C5.3201 1.35997 5.5393 1.14077 5.8097 1.14077H7.7697C8.0401 1.14077 8.2593 1.35997 8.2593 1.63037V2.11997C7.7857 2.11997 5.3201 2.11997 5.3201 2.11997ZM11.6913 3.09917H1.8913C1.6209 3.09917 1.4017 3.31837 1.4017 3.58877C1.4017 3.85917 1.6209 4.07837 1.8913 4.07837H11.6913C11.9617 4.07837 12.1809 3.85917 12.1809 3.58877C12.1809 3.31997 11.9617 3.09917 11.6913 3.09917ZM4.3409 7.01917C4.6113 7.01917 4.8305 7.23837 4.8305 7.50877V13.3888C4.8305 13.6592 4.6113 13.8784 4.3409 13.8784C4.0705 13.8784 3.8513 13.6592 3.8513 13.3888V7.51037C3.8513 7.23997 4.0705 7.01917 4.3409 7.01917ZM6.7905 7.01917C7.0609 7.01917 7.2801 7.23837 7.2801 7.50877V13.3888C7.2801 13.6592 7.0609 13.8784 6.7905 13.8784C6.5201 13.8784 6.3009 13.6592 6.3009 13.3888V7.51037C6.3009 7.23997 6.5201 7.01917 6.7905 7.01917ZM9.2401 7.01917C9.5105 7.01917 9.7297 7.23837 9.7297 7.50877V13.3888C9.7297 13.6592 9.5105 13.8784 9.2401 13.8784C8.9697 13.8784 8.7505 13.6592 8.7505 13.3888V7.51037C8.7505 7.23997 8.9697 7.01917 9.2401 7.01917Z"
                            fill="#464646" />
                        </svg>
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-list-item-content>
              </v-col>
            </v-row>
          </v-list-item>
          <v-divider class="silverDivider"></v-divider>
        </v-list>
      </div>
      <div v-if="!getCard" class="card_content"></div>

      <v-row justify="center" class="ma-0">
        <v-btn href="/card" x-large class="mt-3" :class="Object.keys(getCard).length == 0 ? 'btn_order_dis' : 'btn_order'
          ">
          <span v-if="Object.keys(getCard).length > 0">
            {{ getLang.Card.btn }}
          </span>
          <span v-if="Object.keys(getCard).length == 0">
            {{ getLang.Card.noTovars }}
          </span>
        </v-btn>
      </v-row>
    </v-navigation-drawer>
    <v-main class="main">
      <router-view />
    </v-main>
  </v-app>
</template>

<script>
import CategoriesJSON from "./assets/Categories.json";
import productsJSON2 from "./assets/products.json";
import { mapGetters } from "vuex";

export default {
  name: "App",
  data: () => ({
    drawer: false,
    shops: false,
    menu: false,
    selectedItem: "",
    selectedItem_name: "",
    CategoriesJSON: CategoriesJSON,
  }),
  methods: {
    getIMGPathProduct(url) {
      if (url.includes('http')) return url
      return require(`@/assets/img/products/${url}`);
    },
    changeLang(lang) {
      this.$store.dispatch("SetLanuage", lang);
    },
    OpenMenu() {
      if (
        this.$vuetify.breakpoint.name == "xs" ||
        this.$vuetify.breakpoint.name == "sm"
      ) {
        this.drawer = true;
      } else {
        this.menu = true;
      }
    },
    DeleteFromCard(id) {
      this.$store.dispatch("DeleteCard", id);
    },
    AddTovar(id) {
      this.$store.dispatch("SetCard", id);
    },
    RemoveTovar(id) {
      this.$store.dispatch("RemoveCard", id);
    },
  },
  computed: {
    ...mapGetters(["getLanguage", "getLang", "getCard"]),
    productsJSON() {
      let kol_vo = Object.values(this.getCard);
      let productsJSON = productsJSON2
        .filter((prod) =>
          Object.keys(this.getCard)
            .map((g) => Number(g))
            .includes(prod["ID"])
        )
        .map((c, i) => {
          c.kolicestvo_tovar = kol_vo[i];
          return c;
        });
      return productsJSON;
    },
    Count() {
      let cou = 0;
      this.productsJSON.forEach((element) => {
        cou += element.kolicestvo_tovar;
      });
      return cou;
    },
  },
  created() {
    if (!localStorage.getItem("language")) {
      this.$store.dispatch("SetLanuage", "Ru");
    } else {
      this.$store.dispatch("SetLanuage");
    }
    /*window.onload = function () {
      if (document.getElementById("preloader")) {
        document.getElementById("preloader").style.display = "none";
      }
    };*/
  },
  metaInfo() {
    return {
      title: this.getLanguage == 'Ru' ? "Мотоциклы Husqvarna, Rieju, TM, Fantic для мотокросса, эндуро в Молдове" : "Motociclete Husqvarna, Rieju, TM, Fantic pentru motocross, enduro in Moldova",
      titleTemplate: null,
      content: this.getLanguage == 'Ru' ? "Мотоциклы Эндуро, мотокросс, дорожных мотоциклов, супермото в Молдове! Купите официально мотоцикл Rieju, Husqvarna, TM, Fantic, шины Waygom и запчасти BUD в Молдове в PROENDURO.MD" : "Motociclete Enduro, motocross, motociclete de drum, supermoto în Moldova! Cumpărați oficial o motocicletă Rieju, Husqvarna, TM, Fantic, anvelope Waygom și piese de schimb BUD în Moldova în PROENDURO.MD",
    }
  }
  /*updated() {
    window.onload = function () {
      if (document.getElementById("preloader")) {
        document.getElementById("preloader").style.display = "none";
      }
    };
  },*/
};
</script>
<style>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
<style scoped>
#preloader {
  position: absolute;
  background: #f1f1f1;
  height: 100%;
  width: 100%;
  z-index: 50;
}

.kolicestvo_tovar {
  font-size: 17px;
}

.card_shop_actions {
  border: 0.5px solid #282828;
  border-radius: 2px;
}

.img_moto {
  background: #fff;
}

.btn_order_dis {
  background-color: #dcba10bd !important;
  box-shadow: 0px 4px 21px rgba(228, 185, 72, 0.23);
  border-radius: 9px;
  font-weight: 500;
  font-size: 15px;
  line-height: 103.34%;
  color: #f4f4f4e0;
}

.card_content {
  height: 76vh;
  overflow: auto;
}

.btn_order {
  background-color: #dcbb10 !important;
  box-shadow: 0px 4px 21px rgba(228, 185, 72, 0.23);
  border-radius: 9px;
  font-weight: 500;
  font-size: 15px;
  line-height: 103.34%;
  color: #f4f4f4;
}

.price_shop {
  font-weight: 600;
  font-size: 14px;
  line-height: 105.34%;
  letter-spacing: -0.02em;
  color: #ca9d2a !important;
}

.shop-text {
  color: #dcbb10;
}

.silverDivider {
  border: 1px solid #282828 !important;
}

.list_first_menu {
  height: 100%;
  border-right: 1px solid #cfcfcf;
}

.subtitle_careg_link:hover {
  background: #e6e6e6;
}

.subtitle_careg {
  font-weight: 500;
  font-size: 17px;
  line-height: 20px;
  letter-spacing: -0.04em;
  color: #040c1b;
}

.title_careg {
  font-weight: 300;
  font-size: 17px;
  line-height: 20px;
  letter-spacing: -0.04em;
  text-transform: uppercase;
  color: #040c1b;
}

.categ_tovarov {
  font-weight: 600;
  font-size: 17px;
  line-height: 20px;
  letter-spacing: -0.02em;
  color: #040c1b;
}

.menu_card {
  width: 100vw;
}

.item_drawer_text {
  font-weight: 500;
  font-size: 18px !important;
  line-height: 31px !important;
  letter-spacing: -0.01em !important;
  color: #000000;
}

.item_shop_text {
  font-weight: 500;
  font-size: 18px !important;
  line-height: 31px !important;
  letter-spacing: -0.01em !important;
  color: #efefef;
}

.main {
  max-width: 100%;
  overflow: hidden;
}

.app_bar {
  max-width: 100vw;
}

.shopCard {
  max-width: 520px !important;
  min-width: 320px !important;
  overflow-y: auto;
  max-height: 100vh;
  background: #040c1b;
}

.drawer {
  width: 320px !important;
  overflow-y: auto;
  max-height: 100vh;
}

.item_drawer {
  font-weight: 500;
  font-size: 20px;
  line-height: 31px;
  letter-spacing: -0.01em;
  color: #000000;
}

.title_drawer {
  font-weight: 600;
  font-size: 24px;
  line-height: 30px;
  color: #dcbb10;
  letter-spacing: -0.05em;
}

.yellowDivider {
  border: 1px solid #dcbb10;
  border-color: #dcbb10 !important;
}

.tovar_nr {
  position: absolute;
  top: 0px;
  right: 0px;
  background: #dcbb10;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  text-align: center;
  font-weight: 600;
  font-size: 10px;
  line-height: 10px;
  color: #212121;
  transform: translate(30%, -30%);
}

.title_header {
  text-transform: none;
  font-weight: 700;
  font-size: 17px;
  line-height: 28px;
  letter-spacing: -0.01em;
  color: #eaeaea;
}

.line_black {
  background: #040c1b;
}
</style>
<style>
html,
body {
  scroll-behavior: smooth;
}

.font_wrap {
  word-wrap: break-word !important;
  word-break: normal !important;
}

@font-face {
  font-family: "SFProDisplayLight";
  src: url("./assets/fonts/SFProDisplay-Light.ttf");
}

@font-face {
  font-family: "SFProDisplayRegular";
  src: url("./assets/fonts/SFProDisplay-Regular.ttf");
}

@font-face {
  font-family: "SFProDisplaySemiBold";
  src: url("./assets/fonts/SFProDisplay-SemiBold.ttf");
}

.fontL {
  font-family: "SFProDisplayLight" !important;
}

.fontR {
  font-family: "SFProDisplayRegular" !important;
}

.fontB {
  font-family: "SFProDisplaySemiBold" !important;
}
</style>