<template>
  <v-container fluid class="pa-0 parallaxHome">
    <v-parallax class="pa-0" dark src="../../assets/HomePage/Parallax/1.png" alt="Motocicliști pe motociclete Husqvarna în pădure">
      <v-img class="img_2" src="../../assets/HomePage/Parallax/2.png" alt="logo PRO Enduro black transparent">
        <v-row justify="center" class="fill-height">
          <v-col cols="9" align-self="center">
            <div class="title_text fontB">
              {{ getLang.Parallax_title }}
            </div>
          </v-col>
        </v-row>
      </v-img>
    </v-parallax>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "ParallaxPage",
  computed: {
    ...mapGetters(["getLang"]),
  },
};
</script>

<style scoped>
.title_text {
  margin-top: -180px;
  font-weight: 600;
  font-size: 40px;
  line-height: 100%;
  letter-spacing: -0.02em;
  color: #dcbb10;
}
</style>
<style>
.parallaxHome .v-parallax__content {
  padding: 0px;
}
</style>