<template>
  <v-container fluid class="pa-0 brand_new">
    <v-row justify="center" class="text-center py-15">
      <v-col cols="10" class="">
        <v-row justify="center" class="mt-10 mb-4">
          <v-col cols="12" md="6" align-self="center">
            <v-card flat color="transparent" class="card_left text-left">
              <v-card-title class="card_title pa-0 fontB font_wrap">
                <span v-html="getLang.HomePage.Categories.Brand_new.title"></span>
              </v-card-title>
              <v-card-subtitle
                class="card_subtitle fontR pa-0 mt-1 font_wrap pr-lg-12"
              >
                {{ getLang.HomePage.Categories.Brand_new.subtitle }}
              </v-card-subtitle>
            </v-card>
          </v-col>
          <v-col
            cols="12"
            md="6"
            align-self="center"
            style="position: relative"
          >
            <v-img
              class="img_absolute"
              max-width="150%"
              aspect-ratio="1.5"
              max-height="100%"
              src="../../assets/HomePage/Brand_new/1.png"
              lazy-src="../../assets/HomePage/Brand_new/1_lazy.png"
              alt="Bărbat în haine Husqvarna, Motociclist în cască Husqvarna, Bărbat în cămașă Husqvarna"
            >
            </v-img>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>
  
<script>
import { mapGetters } from "vuex";

export default {
  name: "BrandNew",
  computed: {
    ...mapGetters(["getLang"]),
  },
};
</script>
  
<style scoped>
@media only screen and (max-width: 864px) {
  .img_absolute {
    right: 0%;
  }
}
@media only screen and (min-width: 864px) {
  .img_absolute {
    right: 0%;
    transform: scale(1.5);
  }
}

.card_title {
  font-weight: 600;
  font-size: 52px;
  line-height: 65px;
  letter-spacing: -0.02em;
  color: #dcbb10;
}
.card_subtitle {
  font-weight: 400;
  font-size: 20px;
  line-height: 30px;
  letter-spacing: -0.05em;
  color: #040c1b !important;
}
.brand_new {
  background: url("../../assets/background.png");
}
</style>